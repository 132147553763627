
import React from 'react';

const Venice = () => {
    return (
        <table
            className="MsoNormalTable table-auto"
            border={0}
            cellSpacing={0}
            cellPadding={0}
            width="100%"
            style={{
                width: "100.0%",
                background: "#000000",
                backgroundPosition: "initial initial",
                backgroundRepeat: "initial initial"
            }}
        >
            <tbody>
                <tr>
                    <td valign="top" style={{ padding: "0cm 7.5pt 0cm 7.5pt" }}>
                        <div align="center">
                            <table
                                className="MsoNormalTable"
                                border={0}
                                cellSpacing={0}
                                cellPadding={0}
                                width={600}
                                style={{
                                    width: "450.0pt",
                                    maxWidth: "450pt",
                                    backgroundPosition: "initial initial",
                                    backgroundRepeat: "initial initial"
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
                                            <table
                                                className="MsoNormalTable"
                                                border={0}
                                                cellSpacing={0}
                                                cellPadding={0}
                                                style={{
                                                    background: "black",
                                                    backgroundPosition: "initial initial",
                                                    backgroundRepeat: "initial initial"
                                                }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
                                                            <table
                                                                className="MsoNormalTable"
                                                                border={0}
                                                                cellSpacing={0}
                                                                cellPadding={0}
                                                                width="100%"
                                                                style={{
                                                                    width: "100.0%",
                                                                    background: "black",
                                                                    backgroundPosition: "initial initial",
                                                                    backgroundRepeat: "initial initial"
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            valign="top"
                                                                            style={{ padding: "0cm 0cm 7.5pt 0cm" }}
                                                                        >
                                                                            <div>
                                                                                <div align="center">
                                                                                    <table
                                                                                        className="MsoNormalTable"
                                                                                        border={0}
                                                                                        cellSpacing={0}
                                                                                        cellPadding={0}
                                                                                        width="100%"
                                                                                        style={{
                                                                                            width: "100.0%",
                                                                                            background: "black",
                                                                                            backgroundPosition: "initial initial",
                                                                                            backgroundRepeat: "initial initial"
                                                                                        }}
                                                                                    >
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style={{
                                                                                                        padding: "0cm 0cm 0cm 15.0pt"
                                                                                                    }}
                                                                                                >
                                                                                                    <table
                                                                                                        className="MsoNormalTable"
                                                                                                        border={0}
                                                                                                        cellSpacing={0}
                                                                                                        cellPadding={0}
                                                                                                        style={{ background: "black" }}
                                                                                                    >
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    style={{
                                                                                                                        padding:
                                                                                                                            "0cm 0cm 0cm 0cm"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <p
                                                                                                                        className="MsoNormal"
                                                                                                                        align="center"
                                                                                                                        style={{
                                                                                                                            textAlign: "center"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: "11.0pt",
                                                                                                                                fontFamily:
                                                                                                                                    '"Calibri","sans-serif"'
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__eu.em.pro.sony_v0000p480ggKE0b5B000000&d=DwMFaQ&c=fP4tf--1dS0biCFlB0saz0I0kjO5v7-GLPtvShAo4cc&r=OJblVcthIh5Ier53pR1IzhldNRdJ8W67PzKvAfWxA7g&m=OgFR3sIUPhfl4Iy8wESR7v8zwPXUkXp7h6kggecscAs&s=yWmATh0y1_mxr7Kb2dhuS2uWLzkmSmhNE7Rq30Q1UT8&e=">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        color: "white",
                                                                                                                                        textDecoration:
                                                                                                                                            "none"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <img
                                                                                                                                        border={0}
                                                                                                                                        width={100}
                                                                                                                                        height={50}
                                                                                                                                        id="_x0000_i1025"
                                                                                                                                        src="https://eu.info.pro.sony/rs/063-EBK-070/images/Sony%20logo%20white.png"
                                                                                                                                        alt="SONY"
                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </a>
                                                                                                                        </span>
                                                                                                                    </p>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td
                                                                            width={300}
                                                                            style={{
                                                                                width: "225.0pt",
                                                                                padding: "0cm 0cm 7.5pt 0cm"
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <table
                                                                                    className="MsoNormalTable"
                                                                                    border={0}
                                                                                    cellSpacing={0}
                                                                                    cellPadding={0}
                                                                                    width="100%"
                                                                                    style={{ width: "100.0%" }}
                                                                                >
                                                                                    <tbody>
                                                                                        <tr style={{ height: "7.5pt" }}>
                                                                                            <td
                                                                                                style={{
                                                                                                    padding: "0cm 0cm 0cm 0cm",
                                                                                                    height: "7.5pt"
                                                                                                }}
                                                                                            ></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                style={{
                                                                                                    padding: "0cm 0cm 0cm 0cm"
                                                                                                }}
                                                                                            ></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div>
                                                                <div>
                                                                    <p className="MsoNormal">
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11.0pt",
                                                                                fontFamily: '"Calibri","sans-serif"',
                                                                                color: "white"
                                                                            }}
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <table
                                                                className="MsoNormalTable"
                                                                border={0}
                                                                cellSpacing={0}
                                                                cellPadding={0}
                                                                width="100%"
                                                                style={{
                                                                    width: "100.0%",
                                                                    background: "black",
                                                                    backgroundPosition: "initial initial",
                                                                    backgroundRepeat: "initial initial"
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
                                                                            <div id="mobileImg">
                                                                                <p
                                                                                    className="MsoNormal"
                                                                                    align="center"
                                                                                    style={{ textAlign: "center" }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "1.0pt",
                                                                                            fontFamily: '"Calibri","sans-serif"',
                                                                                            color: "white"
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            href="https://urldefense.proofpoint.com/v2/url?u=https-3A__eu.em.pro.sony_NB009E0g000504K0cp0000g&d=DwMFaQ&c=fP4tf--1dS0biCFlB0saz0I0kjO5v7-GLPtvShAo4cc&r=OJblVcthIh5Ier53pR1IzhldNRdJ8W67PzKvAfWxA7g&m=OgFR3sIUPhfl4Iy8wESR7v8zwPXUkXp7h6kggecscAs&s=bumpwsVDI3KCfgz10K9zB23i11pbzcmncZbgQct8Cg4&e="
                                                                                            target="_blank"
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "white",
                                                                                                    textDecoration: "none"
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    border={0}
                                                                                                    width={1}
                                                                                                    height={1}
                                                                                                    id="_x0000_i1026"
                                                                                                    src="https://eu.info.pro.sony/rs/063-EBK-070/images/VENICE_ES_MOV.png"
                                                                                                    alt="VENICE_ES_MOV"
                                                                                                />
                                                                                            </span>
                                                                                        </a>
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                            <div id="mainImg">
                                                                                <p
                                                                                    className="MsoNormal"
                                                                                    align="center"
                                                                                    style={{ textAlign: "center" }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "1.0pt",
                                                                                            fontFamily: '"Calibri","sans-serif"',
                                                                                            color: "white"
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            href="https://urldefense.proofpoint.com/v2/url?u=https-3A__eu.em.pro.sony_NB009E0g000504K0cp0000g&d=DwMFaQ&c=fP4tf--1dS0biCFlB0saz0I0kjO5v7-GLPtvShAo4cc&r=OJblVcthIh5Ier53pR1IzhldNRdJ8W67PzKvAfWxA7g&m=OgFR3sIUPhfl4Iy8wESR7v8zwPXUkXp7h6kggecscAs&s=bumpwsVDI3KCfgz10K9zB23i11pbzcmncZbgQct8Cg4&e="
                                                                                            target="_blank"
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "white",
                                                                                                    textDecoration: "none"
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    border={0}
                                                                                                    id="_x0000_i1027"
                                                                                                    src="https://eu.info.pro.sony/rs/063-EBK-070/images/VENICE_ESP.png"
                                                                                                    alt="VENICE_ESP.png"
                                                                                                />
                                                                                            </span>
                                                                                        </a>
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ padding: "0cm 0cm 0cm 0cm" }}>
                                                            <table
                                                                className="MsoNormalTable"
                                                                border={0}
                                                                cellSpacing={0}
                                                                cellPadding={0}
                                                                width="100%"
                                                                style={{
                                                                    width: "100.0%",
                                                                    background: "black",
                                                                    backgroundPosition: "initial initial",
                                                                    backgroundRepeat: "initial initial"
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            style={{
                                                                                padding: "30.0pt 45.0pt 7.5pt 45.0pt"
                                                                            }}
                                                                        >
                                                                            <div id="bottomtext">
                                                                                <p
                                                                                    className="MsoNormal"
                                                                                    align="center"
                                                                                    style={{
                                                                                        textAlign: "center",
                                                                                        lineHeight: "16.5pt"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: '"Arial","sans-serif"',
                                                                                            color: "white"
                                                                                        }}
                                                                                    >
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </p>
                                                                                <div>
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        align="center"
                                                                                        style={{ textAlign: "center" }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: "18.0pt",
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            Hola, Buenas Noticias!
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        align="center"
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            lineHeight: "16.5pt"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            &nbsp;
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        align="center"
                                                                                        style={{
                                                                                            marginBottom: "12.0pt",
                                                                                            textAlign: "center",
                                                                                            lineHeight: "16.5pt"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            <br />
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: "16.5pt",
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            VENICE YA SE PUEDE REPARAR EN ESPAÑA
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            &nbsp;
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                            <br />
                                                                                            SONSUR ya es Partner de Servicio
                                                                                            Técnico oficial certificado para
                                                                                            VENICE, y ha invertido en&nbsp;stock
                                                                                            de&nbsp;piezas de repuesto&nbsp;para
                                                                                            asegurar una reparación rápida.
                                                                                            Detalles de contacto: &nbsp;
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        align="center"
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            lineHeight: "16.5pt"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            <br />
                                                                                            Calle Embajadores 150, 28045
                                                                                            Madrid&nbsp;
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                            +34 914748251 &nbsp;
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "#999999"
                                                                                            }}
                                                                                        >
                                                                                            <a href="mailto:juan.rivas@sonsur.com">
                                                                                                juan.rivas@sonsur.com
                                                                                            </a>
                                                                                        </span>
                                                                                        <span className="apple-converted-space">
                                                                                            <span
                                                                                                style={{
                                                                                                    fontFamily:
                                                                                                        '"Arial","sans-serif"',
                                                                                                    color: "white"
                                                                                                }}
                                                                                            >
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            &nbsp;
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                            Juan Rivas&nbsp;
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                            <br />
                                                                                        </span>{" "}
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: "16.5pt",
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            ACCESO DIRECTO A INGENIEROS DE SONY
                                                                                        </span>
                                                                                        <span className="apple-converted-space">
                                                                                            <span
                                                                                                style={{
                                                                                                    fontFamily:
                                                                                                        '"Arial","sans-serif"',
                                                                                                    color: "white"
                                                                                                }}
                                                                                            >
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            &nbsp;
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                            <br />
                                                                                            Adicionalmente hemos mejorado nuestro
                                                                                            Servicio de Sony donde todo dueño de
                                                                                            una VENICE tendrá acceso directo a un
                                                                                            Ingeniero de Sony (Lunes-Viernes de
                                                                                            9:00-17:00) mediante este teléfono:
                                                                                            &nbsp;
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                            <br />
                                                                                            +34 912768732 &nbsp;
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                            Pin 12321
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        align="center"
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            lineHeight: "16.5pt"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            el Pin te identificarte como dueño de
                                                                                            VENICE en España, se te pedirá el
                                                                                            número de serie de VENICE &nbsp;
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                            <br />
                                                                                            <br />
                                                                                            <br />
                                                                                            Echa un vistazo a las producciones que
                                                                                            está siendo grabadas con VENICE en
                                                                                            España y las opiniones de los DoP.
                                                                                            &nbsp;
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div>
                                                                <div>
                                                                    <p className="MsoNormal">
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11.0pt",
                                                                                fontFamily: '"Calibri","sans-serif"',
                                                                                color: "white"
                                                                            }}
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <table
                                                                className="MsoNormalTable"
                                                                border={0}
                                                                cellSpacing={0}
                                                                cellPadding={0}
                                                                width="100%"
                                                                style={{
                                                                    width: "100.0%",
                                                                    background: "black",
                                                                    backgroundPosition: "initial initial",
                                                                    backgroundRepeat: "initial initial"
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            style={{
                                                                                padding: "15.0pt 75.0pt 33.75pt 75.0pt"
                                                                            }}
                                                                        >
                                                                            <div align="center">
                                                                                <table
                                                                                    className="MsoNormalTable"
                                                                                    border={0}
                                                                                    cellSpacing={0}
                                                                                    cellPadding={0}
                                                                                    style={{
                                                                                        background: "#FED206",
                                                                                        backgroundPosition: "initial initial",
                                                                                        backgroundRepeat: "initial initial"
                                                                                    }}
                                                                                >
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td
                                                                                                style={{
                                                                                                    padding:
                                                                                                        "7.5pt 15.0pt 7.5pt 15.0pt"
                                                                                                }}
                                                                                            >
                                                                                                <div id="CTA2">
                                                                                                    <div>
                                                                                                        <p className="MsoNormal">
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: "11.0pt",
                                                                                                                    fontFamily:
                                                                                                                        '"Calibri","sans-serif"'
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    href="https://urldefense.proofpoint.com/v2/url?u=https-3A__pro.sony_es-5FES_product-2Dresources_brochures_shot-2Don-2Dvenice-2D2019&d=DwMFaQ&c=fP4tf--1dS0biCFlB0saz0I0kjO5v7-GLPtvShAo4cc&r=OJblVcthIh5Ier53pR1IzhldNRdJ8W67PzKvAfWxA7g&m=OgFR3sIUPhfl4Iy8wESR7v8zwPXUkXp7h6kggecscAs&s=oQ4qGwj4_AH3KmaKH8CnBuesS954XiK6Ow8MssmaJzc&e="
                                                                                                                    target="_blank"
                                                                                                                    title="Shot on VENICE"
                                                                                                                >
                                                                                                                    <b>
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: "12.0pt",
                                                                                                                                fontFamily:
                                                                                                                                    '"Arial","sans-serif"',
                                                                                                                                textDecoration:
                                                                                                                                    "none"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Shot on VENICE
                                                                                                                        </span>
                                                                                                                    </b>
                                                                                                                </a>
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div>
                                                                <div>
                                                                    <p className="MsoNormal">
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11.0pt",
                                                                                fontFamily: '"Calibri","sans-serif"',
                                                                                color: "white"
                                                                            }}
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <table
                                                                className="MsoNormalTable"
                                                                border={0}
                                                                cellSpacing={0}
                                                                cellPadding={0}
                                                                width="100%"
                                                                style={{
                                                                    width: "100.0%",
                                                                    background: "black",
                                                                    backgroundPosition: "initial initial",
                                                                    backgroundRepeat: "initial initial"
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            style={{
                                                                                padding: "30.0pt 45.0pt 7.5pt 45.0pt"
                                                                            }}
                                                                        >
                                                                            <div id="signature">
                                                                                <div>
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        align="center"
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            lineHeight: "16.5pt"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            Comparte las producciones rodadas con
                                                                                            VENICE
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        align="center"
                                                                                        style={{
                                                                                            marginBottom: "12.0pt",
                                                                                            textAlign: "center",
                                                                                            lineHeight: "16.5pt"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            #ShotonVENICE #SonyCine&nbsp;
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        align="center"
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            lineHeight: "16.5pt"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            <br />
                                                                                            Alizé Amrani
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        align="center"
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            lineHeight: "16.5pt"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: '"Arial","sans-serif"',
                                                                                                color: "white"
                                                                                            }}
                                                                                        >
                                                                                            Regional Marketing Manager, South
                                                                                            Europe
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                            Professional Solutions Europe
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            <br />
                                                                                            D: +33 155903329 | M: +33 620845505
                                                                                            <span className="apple-converted-space">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div>
                                                                <div>
                                                                    <p className="MsoNormal">
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11.0pt",
                                                                                fontFamily: '"Calibri","sans-serif"',
                                                                                color: "white"
                                                                            }}
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <table
                                                                className="MsoNormalTable"
                                                                border={0}
                                                                cellSpacing={0}
                                                                cellPadding={0}
                                                                width="100%"
                                                                style={{
                                                                    width: "100.0%",
                                                                    background: "black",
                                                                    backgroundPosition: "initial initial",
                                                                    backgroundRepeat: "initial initial"
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            style={{ padding: "0cm 15.0pt 0cm 15.0pt" }}
                                                                        />
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div>
                                                                <div>
                                                                    <p className="MsoNormal">
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11.0pt",
                                                                                fontFamily: '"Calibri","sans-serif"',
                                                                                color: "white"
                                                                            }}
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <table
                                                                className="MsoNormalTable"
                                                                border={0}
                                                                cellSpacing={0}
                                                                cellPadding={0}
                                                                width="100%"
                                                                style={{
                                                                    width: "100.0%",
                                                                    background: "white",
                                                                    backgroundPosition: "initial initial",
                                                                    backgroundRepeat: "initial initial"
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            style={{ padding: "0cm 15.0pt 0cm 15.0pt" }}
                                                                        />
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div>
                                                                <div>
                                                                    <p className="MsoNormal">
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11.0pt",
                                                                                fontFamily: '"Calibri","sans-serif"',
                                                                                color: "white"
                                                                            }}
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <table
                                                                className="MsoNormalTable"
                                                                border={0}
                                                                cellSpacing={0}
                                                                cellPadding={0}
                                                                width="100%"
                                                                style={{
                                                                    width: "100.0%",
                                                                    background: "black",
                                                                    minWidth: "100%",
                                                                    backgroundPosition: "initial initial",
                                                                    backgroundRepeat: "initial initial"
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            style={{ padding: "7.5pt 7.5pt 7.5pt 7.5pt" }}
                                                                        >
                                                                            <div align="center">
                                                                                <table
                                                                                    className="MsoNormalTable"
                                                                                    border={0}
                                                                                    cellSpacing={0}
                                                                                    cellPadding={0}
                                                                                >
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td
                                                                                                style={{
                                                                                                    padding: "0cm 0cm 0cm 0cm"
                                                                                                }}
                                                                                            >
                                                                                                <p
                                                                                                    className="MsoNormal"
                                                                                                    align="center"
                                                                                                    style={{ textAlign: "center" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: "11.0pt",
                                                                                                            fontFamily:
                                                                                                                '"Calibri","sans-serif"'
                                                                                                        }}
                                                                                                    >
                                                                                                        <a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__eu.em.pro.sony_q5KE0g00gf0p0400B00c000&d=DwMFaQ&c=fP4tf--1dS0biCFlB0saz0I0kjO5v7-GLPtvShAo4cc&r=OJblVcthIh5Ier53pR1IzhldNRdJ8W67PzKvAfWxA7g&m=OgFR3sIUPhfl4Iy8wESR7v8zwPXUkXp7h6kggecscAs&s=zvtHATVRDMYHiOj4mvORyGkv8paVp_9CzCWKU7as3Vo&e=">
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    textDecoration: "none"
                                                                                                                }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    border={0}
                                                                                                                    width={20}
                                                                                                                    height={20}
                                                                                                                    id="_x0000_i1028"
                                                                                                                    src="http://res.info.pro.sony.eu/res/sonyprofessional/03796464805df743f336bf28d7b10464.png"
                                                                                                                    alt="Facebook"
                                                                                                                />
                                                                                                            </span>
                                                                                                        </a>
                                                                                                    </span>
                                                                                                </p>
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    padding: "0cm 0cm 0cm 0cm"
                                                                                                }}
                                                                                            >
                                                                                                <p
                                                                                                    className="MsoNormal"
                                                                                                    align="center"
                                                                                                    style={{ textAlign: "center" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: "11.0pt",
                                                                                                            fontFamily:
                                                                                                                '"Calibri","sans-serif"'
                                                                                                        }}
                                                                                                    >
                                                                                                        <a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__eu.em.pro.sony_kE0gg0p004000Bg0050d00K&d=DwMFaQ&c=fP4tf--1dS0biCFlB0saz0I0kjO5v7-GLPtvShAo4cc&r=OJblVcthIh5Ier53pR1IzhldNRdJ8W67PzKvAfWxA7g&m=OgFR3sIUPhfl4Iy8wESR7v8zwPXUkXp7h6kggecscAs&s=dOWVOHtjg1tiy0dJ4wxsPwBf7JroK21aA6lehVJjHKk&e=">
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    textDecoration: "none"
                                                                                                                }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    border={0}
                                                                                                                    width={22}
                                                                                                                    height={20}
                                                                                                                    id="_x0000_i1029"
                                                                                                                    src="http://res.info.pro.sony.eu/res/sonyprofessional/d1b902af346585d64fbdd0ea452832b6.png"
                                                                                                                    alt="Twitter"
                                                                                                                />
                                                                                                            </span>
                                                                                                        </a>
                                                                                                    </span>
                                                                                                </p>
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    padding: "0cm 0cm 0cm 0cm"
                                                                                                }}
                                                                                            >
                                                                                                <p
                                                                                                    className="MsoNormal"
                                                                                                    align="center"
                                                                                                    style={{ textAlign: "center" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: "11.0pt",
                                                                                                            fontFamily:
                                                                                                                '"Calibri","sans-serif"'
                                                                                                        }}
                                                                                                    >
                                                                                                        <a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__eu.em.pro.sony_ThpE400050000Be00g00gK0&d=DwMFaQ&c=fP4tf--1dS0biCFlB0saz0I0kjO5v7-GLPtvShAo4cc&r=OJblVcthIh5Ier53pR1IzhldNRdJ8W67PzKvAfWxA7g&m=OgFR3sIUPhfl4Iy8wESR7v8zwPXUkXp7h6kggecscAs&s=OO8nxRAmHA7Vb22-c3YhYzaaTWUaXGptzkxZge_pEDs&e=">
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    textDecoration: "none"
                                                                                                                }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    border={0}
                                                                                                                    width={20}
                                                                                                                    height={20}
                                                                                                                    id="_x0000_i1030"
                                                                                                                    src="http://res.info.pro.sony.eu/res/sonyprofessional/45bff46a2efe629b6ad5fedc1f047963.png"
                                                                                                                    alt="LinkedIn"
                                                                                                                />
                                                                                                            </span>
                                                                                                        </a>
                                                                                                    </span>
                                                                                                </p>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default Venice;